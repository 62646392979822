<template>
    <div class="company-contain">
        <div class="head-box">
            <div class="title" v-if="this.form.id==''">添加商品</div>
            <div class="title" v-else>修改商品</div>
        </div>
        <div class="divide">
            <el-divider></el-divider>
        </div>
        <div class="table-box">
            <el-scrollbar>
                <div class="form-box">
                    <el-form :model="form" label-position="top" ref="form" label-width="110px" :rules="rules">
                        <el-form-item label="商品标题" prop="name">
                            <el-input
                                    type="text"
                                    show-word-limit
                                    v-model="form.name"
                                    maxlength="20"
                                    placeholder="3-20字"
                            ></el-input>
                        </el-form-item>

                        <el-form-item style="position: relative;" label="商品封面" prop="cover">
                            <div class="upload-box">
                                <div class="upload-main" onclick="UploadInput.click()">
                                    <i class="el-icon-plus"></i>
                                    <span>上传封面</span>
                                </div>
                                <input
                                        ref="fileInput"
                                        type="file"
                                        id="UploadInput"
                                        @change="uploadFile($event)"
                                        style="display:none"
                                />
                                <div class="img-box" v-if="form.cover !==''">
                                    <img class="cover-box" :src="form.cover" alt="">
                                    <i class="el-icon-close" @click="delCover"></i>
                                </div>
                            </div>
                            <span class="imggeshi">尺寸800X800</span>
                        </el-form-item>

                        <el-form-item label="商品价格" prop="price">
                            <el-input placeholder="请输入自然数" v-model="form.price"></el-input>
                        </el-form-item>

                        <el-form-item label="商品佣金" prop="commission">
                            <el-input placeholder="请输入自然数" style="width: 150px;" v-model="form.commission"></el-input>
                            <span style="margin-left: 10px;">%</span>
                        </el-form-item>

                        <el-form-item label="优惠券链接" prop="coupon_url">
                            <el-input v-model="form.coupon_url"></el-input>
                        </el-form-item>
                        <el-form-item label="下单链接" prop="url">
                            <el-input v-model="form.url"></el-input>
                        </el-form-item>
                    </el-form>
                    <div class="footer-box">
                        <el-button type="primary" @click="getAddtask('form')">确定</el-button>
                        <el-button @click="handleClose('form')">取消</el-button>
                        <el-button @click="returnBack()">返回</el-button>
                    </div>
                </div>
            </el-scrollbar>
        </div>
    </div>
</template>

<script>
    export default {
        name: 'taskPublish',
        data() {
            return {
                rules: {
                    name: [
                        {required: true, message: "请输入商品标题", trigger: "blur"},
                    ],
                    cover: [{required: true, message: "请上传封面", trigger: "blur"}],
                    price: [
                        {required: true, message: "请输入商品价格", trigger: "blur"},
                        {
                            pattern: /(^[1-9]([0-9]+)?$)/,
                            message: '请输入正确格式'
                        },
                    ],
                    commission: [
                        {required: true, message: "请输入商品佣金", trigger: "blur"},
                        {
                            pattern: /^50$|^([1-4]\d)$|^\d?$/,
                            message: '请输入0-50的自然数'
                        },
                    ],
                    coupon_url: [
                        {required: true, message: "请输入商品链接", trigger: "blur"},
                        {type: 'url', message: '请输入正确的链接地址', trigger: ['blur', 'change']}
                    ],
                    url: [
                        {required: true, message: "请输入商品链接", trigger: "blur"},
                        {type: 'url', message: '请输入正确的链接地址', trigger: ['blur', 'change']}
                    ]
                },
                form: {
                    id: '',
                    name: "",//商品标题
                    cover: "", //封面
                    price: "",//商品价格
                    commission: "",//商品佣金
                    coupon_url: "",//优惠券链接
                    url:"",//下单链接
                },
                editForm: {},
                fileList: [],
                fileLss: [],
            }
        },
        mounted() {
            this.IsEdit();
        },
        methods: {
            IsEdit() {
                this.editForm = this.$route.query.editForm;
                if (typeof this.editForm != "undefined") {
                    this.form = JSON.parse(this.editForm);
                    this.fileList = [{url: this.form.cover}];
                }
            },
            delCover(){
                this.form.cover = '';
            },
            //添加商品
            getAddtask(formName) {
                this.$refs[formName].validate((valid) => {
                    if (valid) {
                        //编辑
                        if (this.form.id != "") {
                            let formData = new FormData();
                            formData.append("goods_id", this.form.id)
                            formData.append('goods_name', this.form.name);
                            formData.append('goods_cover', this.form.cover);
                            formData.append('goods_price', this.form.price);
                            formData.append('goods_commission', this.form.commission);
                            formData.append('coupon_url', this.form.coupon_url);
                            formData.append('goods_url', this.form.url);
                            this.$httpcompany.axiosPost(this.$api.goodsSave, formData, (res) => {
                                if (res.code == 200) {
                                    this.$message.success('编辑成功！');
                                    this.editForm = {};
                                    this.handleClose(formName);
                                    this.$router.push('/company/product/index')
                                } else {
                                    this.$message.warning('编辑失败')
                                }
                            }, err => {
                                this.$message.warning('编辑失败')
                            })
                        } else {
                            let formData = new FormData();
                            formData.append('goods_name', this.form.name);
                            formData.append('goods_cover', this.form.cover);
                            formData.append('goods_price', this.form.price);
                            formData.append('goods_commission', this.form.commission);
                            formData.append('coupon_url', this.form.coupon_url);
                            formData.append('goods_url', this.form.url);
                            this.$httpcompany.axiosPost(this.$api.goodsSave, formData, (res) => {
                                if (res.code == 200) {
                                    this.$message.success('添加成功！');
                                    this.editForm = {};
                                    this.handleClose(formName);
                                    this.$router.push('/company/product/index')
                                } else {
                                    this.$message.warning('添加失败')
                                }
                            }, err => {
                                this.$message.warning('添加失败')
                            })
                        }
                    }
                })
            },
            //取消
            handleClose(formname) {
                this.$refs[formname].resetFields();
                this.fileLss = [];
                this.fileList = [];
                this.form.id = "";
            },
            returnBack() {
                this.$router.push({path: "/company/product/index"});
                this.editForm = {};
            },
            uploadFile(event) {
                let that = this;
                let fileName = event.target.files[0].name;
                let file = event.target.files[0];
                let limiteFileType = ["jpg", "png"];
                if (
                    !limiteFileType.includes(
                        fileName.split(".")[fileName.split(".").length - 1]
                    )
                ) {
                    this.$message.warning("文件格式错误！");
                    return;
                }
                const isLt1M = file.size / 1024 / 1024 < 1;
                const isSize = new Promise(function (resolve, reject) {
                    let width = 800
                    let height = 800
                    let _URL = window.URL || window.webkitURL
                    let img = new Image()
                    img.onload = function () {
                        let valid = img.width == width && img.height == height
                        valid ? resolve() : reject();
                    }
                    img.src = _URL.createObjectURL(file)
                }).then(() => {
                    let formData = new FormData();
                    formData.append("file", file);
                    this.$httpcompany.axiosPost(this.$api.uploadImg, formData, res => {
                            if (res.code == 200) {
                                that.form.cover = res.data.src;
                            } else {
                                this.$message({
                                    type: "error",
                                    message: res.msg,
                                    duration: 1000
                                });
                            }
                        },
                        err => {
                            console.log(err);
                        }
                    );
                }, () => {
                    this.$message.error('上传图片尺寸限制为800*800!');
                    return Promise.reject();
                }).catch((e) => {
                })
            },
            //移除之前
            beforeRemove(file, fileList) {
                return this.$confirm(`确定移除 ${file.name}？`, {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.fileList.splice(file, 1)
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                });
            },
        }

    }
</script>

<style scoped lang="scss">
    .activeClass {
        background: #4d5aff;
        color: #ffffff;
    }

    .company-contain {
        background-color: #ffffff;
        display: flex;
        flex-direction: column;
        height: calc(100vh - 100px);

        .head-box {
            display: flex;
            justify-content: space-between;
            height: 40px;
            padding: 30px 30px 0 40px;

            .title {
                margin-top: 10px;
            }
        }

        .divide {
            padding: 0 20px;
        }

        .table-box {
            display: flex;
            flex-direction: column;
            height: 1%;
            flex: 1;

            ::v-deep .el-scrollbar__wrap {
                overflow-x: hidden;
            }

            .form-box {
                width: 100%;
                flex: 1;
                height: 1%;
                position: relative;
                padding-left: 70px;

                .el-input {
                    width: 330px;
                }

                .footer-box {
                    justify-content: center;
                    display: flex;
                    padding: 220px 0 50px;

                    ::v-deep .el-button {
                        width: 100px;
                        height: 32px;
                        line-height: 9px;
                    }

                    ::v-deep .el-button--primary {
                        background: #1E33E4;
                    }

                    ::v-deep .el-button+.el-button {
                        margin-left: 20px;
                    }
                }
            }

            .upload-box {
                display: flex;

                .upload-main {
                    width: 200px;
                    height: 200px;
                    cursor: pointer;
                    background: #F1F1F1;
                    color: #4D5AFF;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;

                    i {
                        font-size: 36px;
                    }
                }

                .showImg-box {
                    margin-left: 20px;

                    &:nth-child(1) {
                        margin-left: 0px;
                    }

                    .logo-box {
                        width: 100px;
                        height: 100px;
                        border: 1px solid #EEEEEE;
                        border-radius: 2px;
                    }

                    .qa-box {
                        max-width: 154px;
                        max-height: 212px;
                    }
                }

                .img-box {
                    margin-left: 24px;
                    position: relative;

                    .cover-box {
                        width: 200px;
                        height: 200px;
                    }

                    .el-icon-close {
                        position: absolute;
                        top: 0;
                        right: -10px;
                        cursor: pointer;
                    }
                }
            }
        }

        .filter-box {
            .box1 {
                padding: 0 20px 10px 20px;
                height: 40px;
                display: flex;

                span {
                    margin-top: 8px;
                    line-height: 32px;
                    display: inline-block;
                    width: 80px;
                    height: 32px;
                    text-align: center;
                    cursor: pointer;
                }
            }
        }

        .imggeshi {
            position: absolute;
            left: 120px;
            top: -50px;
            color: #999999;
            font-size: 12px;
        }

        .addimg {
            display: block;
            width: 22px;
            height: 22px;
            background: #1d33e3;
            color: #ffffff;
            border-radius: 50%;
            position: absolute;
            font-size: 18px;
            line-height: 22px;
            text-align: center;
            left: 30%;
            top: -80%;
        }
    }
</style>
